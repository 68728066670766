.single-card {
    background-color:  $white;
    width: 100%;
    border-radius: 5px;
    box-shadow: 0 0 10px $olive;
    margin-bottom: 15px;

    .single-card-title{
        margin: 0;
        padding: 10px;
        color: $dark-gray;
        text-align: left;
        font-size: 1.4rem;
        font-weight: 700;
        line-height: 1.5rem;
        display: flex;
        align-items: center;
        border-bottom: 2px solid $olive;
    }

    .single-card-content{
        margin: 0;
        padding: 12px 22px ;
        color: $dark-gray;
        text-align: left;
        font-size: 1.1rem;
        font-weight: 500;
        line-height: 1.2rem;
    }
}