.carParts-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    padding-bottom:50px ;
    
    .add-new-button{
        cursor: pointer;
        border: none;
        background-color: $sand;
        color: $white;
        padding: 7px 15px;
        font-size: 1.1rem;
        max-width: 200px;
        border-radius: 3px;

        &:hover{
            background-color: $orange;
        }
    }

    span{
        color: $sand;
    }

    .carParts-gallery{
        margin-top: 10px;
        width: 100%;
        background-color: $white;
        border-top: 1px solid $sand;
        display: flex;
        flex-wrap: wrap;

        .first-col{
            margin-top: 10px;
            margin-bottom: 10px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 20px;
            width: 100%;

            .delete-button{
                border: none;
                background-color: $red;
                color: $white;
                cursor: pointer;
                border-radius: 5px;
                padding: 7px;
                height: 35px;
              

            }
        }

        p{
            margin: 0;
            padding: 0;
            color: $sand;
        }

        .add-part-img-form{
            width: 200px;
            height: 150px;
            img{
                width: 100%;
                height: 100%;
            }
        }

        .cards{
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            gap: 10px;
            padding: 10px;

            .img-box{
                width: 200px;
                height: 150px;
                position: relative;

                img{
                    width: 100%;
                    height: 100%;
                }
                button{
                    position: absolute;
                    top: 5px;
                    left: 5px;
                    z-index: 5;
                    background-color: red;
                    color: $dark-gray;
                    border: none;
                    cursor: pointer;
                    width: 35px;
                    height: 35px;
                    border-radius: 5px;
                    font-size: 1.5rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }
 
}