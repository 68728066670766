@import '../../../../styles/variables';

.cardWithButton-container {
    // background-color: $white;
    width: 100%;
    border-radius: 5px;
    // box-shadow: 0 0 10px $olive;

    .cardWithButton-title{
        margin: 0;
        padding: 10px;
        color: $dark-gray;
        background-color: $white;
        text-align: left;
        font-size: 1.4rem;
        font-weight: 700;
        line-height: 1.5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $olive;
        box-shadow: 0 0 10px $olive;
        border-radius: 5px 5px 0 0;

        .cardButton{
            margin: 0;
            padding: 0;
            font-size: 1.5rem;
            border: none;
            cursor: pointer;
            color: $dark-gray;
            background-color: $white;
            display: flex;
            align-items: center;

            &:hover{
                color: $orange;
            }
        }
    }

    .cardWithButton-content{
        margin: 0;
        padding: 12px 0 ;
        color: $dark-gray;
        text-align: left;
        font-size: 1.1rem;
        font-weight: 500;
        line-height: 1.2rem;
    }
}

@media screen and (max-width: 500px){
    .cardWithButton-container{
        .cardWithButton-content{
          
            padding: 12px 0 ;
         
        }
    }
    
}