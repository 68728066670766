.update-car-info{
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;

    .car-title{
        margin: 20px 0 0;
        width: 100%;
        text-align: center;
        font-size: 1.4rem;
        font-weight: 700;
        color: $dark-gray;
    }

    .car-content{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 30px;

        .left-box{
            flex: 1;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 15px;

            .img-collection{
                width: 100%;
                display: flex;
                justify-content: space-around;
                flex-wrap: wrap;
                gap: 10px;

                .img-box{
                    width: 100px;
                    position: relative;
                    img{
                        width: 100%;
                        height: 100%;
                        display: block;
                    }

                    button{
                        position: absolute;
                        z-index: 5;
                        top: 5px;
                        left: 5px;
                        border: none;
                        border-radius: 5px;
                        height: 30px;
                        width: 30px;
                        background-color: $red;
                        font-size: 1.1rem;
                        cursor: pointer;

                        &:hover{
                            background-color: lighten($red, 20%);
                        }
                    }
                }
            }

        }

        .right-box{
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;

            .car-info-table{
                margin: 0;
                padding: 0;
                list-style-type: none;
                display: flex;
                flex-direction: column;
                gap: 10px;

                li{
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    flex-wrap: wrap;

                    label{
                        color: $gray;
                        font-size: 1rem;
                        width: 170px;
                    }

                    input{
                        color: $dark-gray;
                        padding: 5px;
                        font-size: 1.1rem;
                        outline: 1px solid $light-gray;
                        border: 1px solid $light-gray;
                        border-radius: 3px;
                    }
                    select{
                        color: $dark-gray;
                        padding: 5px;
                        font-size: 1.1rem;
                        outline: 1px solid $light-gray;
                        border: 1px solid $light-gray;
                        border-radius: 3px;
                    }

                    textarea{
                        width: 200px;
                        height: 100px;
                        color: $dark-gray;
                        padding: 5px;
                        font-size: 1.1rem;
                        outline: 1px solid $light-gray;
                        border: 1px solid $light-gray;
                        border-radius: 3px;

                    }
                }
            }


        }
    }

    .update-button{
        border: none;
        background-color: $dark-gray;
        color: $white;
        font-size: 1rem;
        height: 35px;
        width: 120px;
        border-radius: 3px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover{
            background-color: $orange;
        }
    }

    .car-id{
        margin: 0;
        font-size: 0.8rem;
        font-weight: 400;
        color: $gray;
    }
}

@media screen and (max-width: 650px) {
    .update-car-info{
        .car-content{
            .left-box{

            }
            .right-box{
                align-items: center;
                .car-info-table{
                    li{
                        justify-content: center;
                        label{
                            text-align: left;
                            width: 100%;
                        }
                        input{
                            text-align: left;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}