.carContainer{
    background-color: $white;
    color: $dark-gray;
    margin: 0 0 15px;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    .top{
        display: flex;
        justify-content: space-between;
        align-items: center;

        span{
            font-size: 1.1rem;
            font-weight: bold;
        }
        
        button{
            background-color: $dark-gray;
            border: none;
            width: 22px;
            height: 22px;
            border-radius: 3px;
            font-size: 22px;
            color: $white;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            &:hover{
                background-color: darken($dark-gray, 20%);
            }
        }
    }

    .carContainer-content{
        .actionSection{
            max-width: 500px;
            margin-top: 10px;
            padding-top: 10px;
            border-top: 1px solid $dark-gray;
            display: flex;
            justify-content: space-between;
            gap: 50px;

            .actionButton{
                cursor: pointer;
                background-color: transparent;
                width: 25px;
                height: 25px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 3px;
                color: $dark-gray;
                border: 2px solid $dark-gray;
                box-shadow: $gray 0px 2px 3px;

                &:hover{
                    border: 2px solid darken($dark-gray, 25%);
                    color: darken($dark-gray, 25%);
                    box-shadow: $orange 0px 2px 3px;
                }
            }
        }

        // singel image
        .uploadSingelImg-container{
            width: 100%;
            margin-top: 10px;
            padding: 10px;
            border-top: 1px solid $dark-gray;

            .top-box{
                display: flex;
                flex-direction: column;
                align-items: center;

                img{
                    margin: 0 0 20px;
                    padding: 0;
                    width: 250px;
                    box-shadow: rgba(0, 0, 0, 0.34) 0px 3px 8px;
                }

                input, button{
                    padding: 7px;
                    width: 250px;
                    background-color: $dark-gray;
                    color: $white;
                    border-radius: 3px;
                    cursor: pointer;
                    border: none;
                    box-shadow: rgba(0, 0, 0, 0.34) 0px 3px 8px;

                    &:hover{
                        background-color: $orange;
                    }
                }
            }
        }

        // CarGallery
        .carGallery{
            padding: 15px 0;
            display: flex;
            justify-content: space-around;
            gap: 10px;
            flex-wrap: wrap;

            .img-box{
                width: 150px;
                border: 1px solid $light-gray;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                

                img{
                   display: block;
                   width: 100%;
                   //    height: 80px;
                    //    width: 148px;
                    //    height: 80px;
                    //    object-fit: cover;  
                }
                button{

                    position: absolute;
                    padding: 4px 5px 2px;
                    background-color: darken($red, 20%);
                    color: $white;
                    top: 3px;
                    left: 3px;
                    border: none;
                    border-radius: 3px;
                    cursor: pointer;
                    z-index: 1;

                    &:hover{
                        background-color: darken($red, 25%);
                    }

                }
            }
        }

        //carParts-container
        .carParts-container{
            margin-top: 10px;
            border-top: 1px solid $dark-gray;
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 10px;


            .add-new-button{
                width: 130px;
                padding: 7px 10px;
                background-color: $sand;
                color: $white;
                margin: 10px auto 20px;
                border: 1px solid $gray;
                cursor: pointer;
                border-radius: 5px;
            }

            span{
                font-size: 1rem;
                color: $dark-gray;
            }

            .carParts-gallery{
                background-color: $sand;
                padding: 10px 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                
                .top-box{
                    width: 100%;
                    padding: 0 15px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;

                    .delete-button{
                        margin-bottom:10px;
                        border: none;
                        background-color: darken($red, 20%);
                        color: $white;
                        padding: 7px;
                        border-radius: 3px;
                        cursor: pointer;

                        &:hover{
                            background-color: darken($red, 30%);
                        }

                    }
                }
                .add-part-img-form{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    img{
                        display: block;
                        width: 250px;
                    }

                    form{
                        margin-top: 20px;
                        margin-bottom: 20px;

                        input, button{
                            width: 250px;
                            padding: 7px ;
                            background-color: $dark-gray;
                            color: $white;
                            border: none;
                            border-radius: 3px;
                            cursor: pointer;


                            &:hover{
                                background-color: $orange;
                            }
                        }
                    }
                }

                p{
                    margin: 0;
                    padding: 10px 0;
                    font-size: 1.25rem;
                }

                .cards{
                  
                    padding-top: 10px;
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    flex-wrap: wrap;
                    gap: 10px;

                    .img-box{
                        width: 150px;
                        border: 1px solid $light-gray;
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

                        img{
                            display: block;
                            width: 100%;
                        }

                        button{
                            position: absolute;
                            top: 3px;
                            left: 3px;
                            width: 25px;
                            height: 25px;
                            background-color: darken($red, 20%);
                            color: $white;
                            cursor: pointer;
                            border: none;
                            border-radius: 3px;

                            &:hover{
                                background-color: darken($red, 30%);
                            }
                        }
                    }



                }
            }
        }
      
    }
}

.activeCard{
    box-shadow: $orange 0px 3px 8px;
}


