.addParts-container{
    margin: 0 0 50px;
    width: 100%;
    display: flex;
    flex-direction: column;

    .addParts-links{
        display: flex;
        justify-content: space-between;
        padding: 10px 0 20px;
        
        .link{
            background-color: $dark-gray;
            color: $white;
            text-decoration: none;
            display: block;
            padding: 8px 30px;
            border-radius: 3px;
            text-align: center;
            box-shadow: 0 0 5px $dark-gray;
            border: 1px solid $dark-gray;
            &:hover{
                background-color: $orange;
                border: 1px solid lighten($gray, 5%);
            }
        }

    }

    span{
        margin: 0;
        width: 100%;
        font-size: 1rem;
        font-weight: 400;
        color:$dark-gray;
    }
}

.plPartsAddForm{
    margin: 10px auto;
    max-width: 1200px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;

    input{
        border: 1px solid $gray;
        outline: none;
        border-radius: 5px;
        padding: 0 10px;
        box-shadow: 0 0 5px $dark-gray;
    }

    .partName{
        width: 300px;
    }
    .code{
        width:150px;
    }
    .units{
        width:105px;
    }

    button{
        border: none;
        background-color: $dark-gray;
        color: $white;
        font-size: 2rem;
        padding: 0 10px;
        border-radius: 5px;
        cursor: pointer;
        box-shadow: 0 0 5px $dark-gray;

        &:hover{
            background-color: $orange;
            color: $gray;
        }
    }
}

@media screen and (max-width: 1000px){
    .plPartsAddForm{
        flex-direction: column;

        input{
            padding: 5px 10px;
        }
        .partName,.code, .units{
            width: 100%;
        }
       
    }
}