.car-title{
    margin: 50px auto;
    width: 500px;
    font-size: 2rem;
    line-height: 2.1rem;
    height: 35px;
}

.carPage-container{
    margin: 0 auto;
    max-width: 1200px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 30px;

    .car-images{
        flex: 1;
    }

    .car-info{
        flex: 1;

        ul{
            margin: 0;
            padding: 0;
            list-style-type: none;
            display: flex;
            flex-direction: column;
            gap: 5px;

            li{
                border-bottom: 1px solid $light-gray;
                
                display: flex;
                flex-direction: row;
                // justify-content: flex-start;
                align-items: center;
                
                span{
                    margin: 0;
                    padding: 3px 0;
                    display: block;
                    min-width: 170px;
                    flex: 1;
                    font-size: 1.1rem;
                    font-weight: 400;
                    color: lighten($gray, 12%);
                }

                h3{
                    margin: 0;
                    padding: 3px 0;
                    flex: 2;
                    display: flex;
                    justify-content: flex-start;
                    font-size: 1.1rem;
                    font-weight: 600;
                    color: $dark-gray;
                }

                h6{
                    margin: 0;
                    padding: 10px 0;
                    font-weight: 400;
                    color: lighten($gray, 30%);
        
                }

                &:last-child{
                    border-bottom: none;
                }
            }
        }

    }
}

.carPage-links{
    margin: 50px 0;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 50px;

    .link{
        text-decoration: none;
        background-color: $dark-gray;
        border: none;
        color: $white;
        width: 120px;
        padding: 7px 15px;
        font-size: 1.1rem;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 3px;

        &:hover{
            background-color: $orange;
        }
    }


}


.albums-App{
    width: 100%;

    .albums-app-container{
        margin: auto;
        width: 100%;
        max-width: 540px;

        img {
            display: block;
            cursor: pointer;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        }
        .albums-selected {
            width: 100%;
            // width: 520px;
            height: 390px;
            margin: 0 auto;
            border: 3px solid $sand;
            object-fit: cover;
            object-position: center center;
        
        }
        
        .albums-imgContainer{
            width: 100%;
            display: flex;
            justify-content:flex-start;
            flex-wrap: wrap;
            gap: 10px;
            padding: 40px 0 0 0;

            img{
                width: 80px;
                height: 100%;
                margin: 5px 0;
                box-shadow: 1px 2px 5px 2px $gray;
            }
        }
    }
    .show-big-image{
        position: fixed;
        z-index: 100;
        background-color: darken($dark-gray, 8%);
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 15px;
      
        .img-container{
            // margin: 15px;
            max-width: 800px;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            gap: 15px;

            button{
                width: 40px;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                border: none;
                background-color: darken($dark-gray, 8%);
                font-size: 2.5rem;
                color: $light-gray;
            }

            // img{
            //     width: 100%;
            //     height: 100%;
            // }

            .slider {
                position: relative;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0;
                padding: 0;
        
                .left-arrow,
                .right-arrow {
                    position: absolute;
                    z-index: 1;
                    font-size: 20px;
                    top: 46%;
                    color: white;
                    background-color: darken($dark-gray, 8%);
                    border-radius: 50%;
                    padding: 7px 8px;
                    cursor: pointer;
                    user-select: none;
                }
        
                .left-arrow {
                    left: 5%;
                }
                  
                .right-arrow {
                    right: 5%;
                }
        
                .slide {
                    min-width: 50%;
                    height: 60vh;
                    font-size: 50px;
        
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
            }

        }

    }

}


@media screen and (max-width: 1000px) {
    .car-title{
        width: 100%;
    }
    .carPage-container{
        width: 100%;
        flex-direction: column;
        .car-images{
            width: 100%;
            height: 500px;
            .albums-selected {
                width: 100%;
                height: 100%;
                margin: 0 auto;
                // border: 3px solid $sand;
            
            }
          
        }
    }
}

@media screen and (max-width: 650px) {
    .carPage-container{
        .car-info{
            ul{
                li{
                    flex-direction: column;
                    align-items: flex-start;
                }
            }
        }

    }
    // .car-title{
    //     width: 100%;
    // }
    // .carPage-container{
    //     width: 100%;
    //     flex-direction: column;
    //     .car-images{
    //         width: 100%;
    //         height: 500px;
    //         .albums-selected {
    //             width: 100%;
    //             height: 100%;
    //             margin: 0 auto;
    //             border: 3px solid $orange;
            
    //         }
          
    //     }
    // }
}

@media screen and (max-width: 400px) {
    .albums-App{
        .albums-app-container{
            .albums-imgContainer{
                justify-content:space-around;
            }
        }
    }
}