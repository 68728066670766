html, body {
    margin: 0;
    font-size: 16px;
    font-family: $font-nunito;
    background: $olive-rgba;
}

img {display: block;}

.container{margin: 0 auto;padding: 0 15px;max-width: 1294px;}



