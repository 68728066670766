.partsList{
    margin: 20px auto 0;
    max-width: 1200px;
    display: flex;
    justify-content: center;

    .table{
        margin: 0;
        width: 100%;
        display: block;

        thead, tbody{
            width: 100%;
            color: $black;

            p{
                margin: 20px 0;
                padding: 0;
                text-align: center;
                color: $dark-gray;
            }

            tr{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                th{
                    margin: 1px;
                    font-size: 0.8rem;
                    font-weight: 300;
                    background-color: $light-gray;
                    height: 60px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid $gray;
                }
                td{
                    margin: 1px;
                    height: 40px;
                    display: flex;
                    font-size: 0.9rem;
                    font-weight: 400;
                    border: 1px solid $gray;
                    justify-content: center;
                    align-items: center;
                }

                .partsList-number{
                    padding-left: 2px;
                    width: 60px;
                    
                }
                .partsList-name{
                    width: 100%;

                    &.list{
                        font-size: 1rem;
                    }
                  
                }
                .partsList-col{
                    width: 130px;
                    display: flex;
                    flex-direction: column;
                }
                .partsList-buttons{
                    width: 130px;
                    display: flex;
                    justify-content: space-around;
                    align-items: center;  

                    button{
                        background-color: $olive;
                        border: 2px solid $dark-gray;
                        border-radius: 3px;
                        width: 25px;
                        height:25px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: $light-gray;
                        cursor: pointer;
                        &:first-child{
                            color: darken($red, 20%);
                        }
                    }
                }

            }
            .edit{
                background-color: $olive;
                display: flex;
                justify-content: space-around;
                padding: 10px 0;

                .partsList-edit{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-end;
                    label{
                        color: $light-gray;
                    }
                    input{
                        padding: 5px;
                        border: none;
                        outline: none;
            
                        &::placeholder{
                            font-size: 1rem;
                            color: $gray;
                        }
                    }
                    button{
                        border: none;
                        padding: 5px 20px;
                        background-color: $orange;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .partsList-pc-screen{
        display: block;
        border-radius: 5px;
        padding: 5px;
    }

    .partsList-mobile-screen{
        display: none;
    }

    ul{
        margin: 0;
        padding: 0;
        list-style-type: none;
        border: 1px solid $dark-gray;

        .partsList-info{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 8px;

        }

        .partsList-container{
            width: 100%;
            display: flex;
            flex-direction: row;

            &:hover{
                background-color: lighten($olive, 50%);
            }

            &.top{
                background-color: $light-gray;
              
            }
            span{
                margin:1px;
                padding: 8px 3px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: $black;
                border: 1px solid darken($light-gray, 20%);
                text-align: center;
            }
    
            .number{
                width: 50px;
            }
            .partName{
                width: 400px; 
            }
            .units{
                width: 50px;
            }
            .partWeight{
                width: 50px;
            }
            .action{
                width: 50px;

                &.buttons{
                    display: flex;
                    justify-content: space-around;
                    align-items: center;  
                    gap: 5px;

                    button{
                        background-color: $dark-gray;
                        border: none;
                        border-radius: 3px;
                        width: 25px;
                        height:25px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: $light-gray;
                        cursor: pointer;
                        &:first-child{
                            color:$light-gray;
                        }
                    }
                }
            }
    
    
           
        }
    }
    
}

.updateItem{
    display: flex;
    flex-direction: column;
    background-color: lighten($olive, 10%);
    color: $light-gray;
   
    li{
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 5px;
        margin-top: 10px;

        input{
            padding: 5px 10px;
        }

        button{
            background-color: $dark-gray;
            border: none;
            padding: 8px 10px;
            border-radius: 5px;
            cursor: pointer;
            color: $white;
            &:hover{
                background-color: $orange;
            }
        }
    }
}

@media screen and (max-width: 950px){
    .partsList{
        .partsList-pc-screen{
            display: none;
           
        }
        .partsList-mobile-screen{
            display: block;
            width: 100%;
            border-radius: 5px;
            padding: 5px;

            .partsList-container{
                flex-direction: column;
                padding: 2px;
                margin-top: 10px;
                border-radius: 3px;
                box-shadow: 0 0 5px $olive;
               
                div{
                    display: flex;
                    justify-content: space-between;

                    .partName{
                        width: 100%;
                    }
                    .units{
                        width: 105px;
                    }
                    .partWeight{
                        width: 170px;
                    }
                }
            }
        }
    }
}
