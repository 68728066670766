.page-not-found{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    
    h2{
        margin: 0;
        padding: 30px;
        border-radius: 5px;
        background-color: lighten($red, 42%);
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 30px;
    }

    .link{
        color: $dark-gray;
        font-size: 1.1rem;
        cursor: pointer;
        
        &:hover{
            color: lighten($dark-gray, 5%);
        }
    }
}