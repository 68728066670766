.add-new-car-form{
    background-color: $white;
    color: $dark-gray;
    width: 100%;
    padding: 15px;
    // margin-bottom: 15px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    h1{
        margin: 0;
        font-size: 1.5rem;
    }

    span{
        font-size: 1.1rem;
        color: darken($red, 10%); 
        display: block;  
        padding: 5px;
        border: 1px solid darken($red, 10%);
        border-radius: 3px;
        background-color: lighten($red, 45%);
      
    }

    form{
        display: flex;
        justify-content: center;
        gap: 30px;

        select{
            padding: 5px;
            outline: none;
            border-radius: 3px;
            width: 180px;
            height: 36px;
            cursor: pointer;
            font-size: 1.1rem;
        }

        button{
            border: none;
            border-radius: 3px;
            display: flex;
            align-items: center;
            width:36px;
            height: 36px;
            background-color: $dark-gray;
            display: flex;justify-content: center;
            align-items: center;

            .icon{
                background-color: $dark-gray;
                font-size: 32px;
                color: aliceblue;
                border-radius: 3px;
                cursor: pointer;

                &:hover{
                    font-size: 28px;
                    color: aliceblue;
                    border-radius: 3px;
                    background-color: lighten($dark-gray, 10%);
                }
            }

            &:hover{
                background-color: lighten($dark-gray, 10%);
            }

        }
    }
}

@media screen and (max-width: 1000px) {
    .add-new-car-form{
        flex-direction: column;
        gap: 10px;
        span{
            margin-bottom: 5px; 
        }
    }
}
