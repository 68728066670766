@import '../../../styles/variables';

.sidebar-menu-with-title{
    display: flex;
    flex-direction: column;
    background-color: darken($dark-gray, 8%);
    width: 270px;
    height: 100%;
    top: 71px;
    left: -100%;
    position: fixed;
    z-index:10;

    &.active {
        width: 270px;
        left: 0px;
    }

    .sidebar-menu-items {
        width: 270px;

        li {
            width: 100%;
            margin: .6rem 0;
            padding-left: 1rem;

            .sidebar-links {
                padding: .8rem 3rem .8rem 1.1rem;
                text-decoration: none;
                display: block;
                color: $white;
                font-size: 1.1rem;
                display: flex;
                justify-content: space-between;

                &:hover {
                    color: $orange;
                }

                &.active {
                    background-image: url(../../../images/bgx-o.png);
                    color: darken($dark-gray, 5%);
                    padding-top: .8rem;
                    padding-bottom: .5rem;
                    font-weight: 700;
                    border-radius: 30px 0 0 30px;

                    &:hover {
                        color: $orange;
                    }
                }

                span{
                    font-size: 1.3rem;
                }
            }
        }

        .sidebar-menu-userEmail{
           display: none;
         }
    }

}

@media screen and (max-width: 800px){
    .sidebar-menu-with-title{
        top: 66px;
        .sidebar-menu-items{
            .sidebar-menu-userEmail{
               display: flex;
               flex-direction: column;
               align-items: center;
               gap: 20px;
               margin-top: 50px;

               .userImage{
                    height: 34px;
                    display: block;
                    border-radius: 50%;
                    margin: auto 10px;
                }

                h4{
                    font-weight: 400;
                    color:  $white;
                }

                small {
                    display: inline-block;
                    color: $olive;
                    font-weight: 400;
                }
                .logout-button{
                    text-align: center;
                    width: 130px;
                    padding: 8px 18px;
                    font-size: 1.15rem;
                    font-weight: 500;
                    color: $light-gray;
                    border-radius: 5px;
                    border:none;
                    cursor: pointer;
                    background-image: url(../../../images/bgx-o.png);

                    &:hover{ background-image: url(../../../images/bgx-2.jpg);}
                }
            }
        }
    }
}