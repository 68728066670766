@import '../../../styles/variables';

.sidebar-icons-menu{
    position: fixed;
    left: 0;
    top: 71px;
    z-index:90;
    height: 100%;
    width: 90px;
    display: flex;
    flex-direction: column;
    background-color: darken($dark-gray, 8%);
    opacity: 1;

    .sidebar-menu-items {

        li {
            width: 100%;
            margin: .6rem 0;
            padding-left: 1rem;
        }

        .sidebar-links {
            padding: .8rem 3rem .8rem 1.1rem;
            text-decoration: none;
            display: block;
            color: $white;
            font-size: 1.1rem;
            display: flex;
            justify-content: space-between;

            &:hover {
                color: $orange;
            }

            &.active {
                background-image: url(../../../images/bgx-o.png);
                color: darken($dark-gray, 5%);
                padding-top: .8rem;
                padding-bottom: .5rem;
                font-weight: 600;
                border-radius: 30px 0 0 30px;
                &:hover{
                    color: $orange;;
                }
            }

            span{
                font-size: 1.3rem;
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .sidebar-icons-menu {
        display: none;
    } 
}