@import '../../../styles/variables';

.dashboard-header{
    margin: 0;
    background-color: darken($dark-gray, 8%);
    position: fixed;
    width: 100%;
    z-index:100;
    padding: 20px;

    .dashboard-navigation{
        display: flex;
        width: 100%;
        justify-content: space-between;

        .navigation-left-box{
            width: 300px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 2px 0 2px 10px;

            a{
                margin: 0;
                padding: 0;
                display: block;
                color: $white;

                &:hover{
                    color: $orange;
                }
                &.logo-style{
                    display: block;
                }
                &.dashboard-menu-bars {
                    margin-left: 40px;
                    font-size: 1.7rem;
                    display: flex;
                    align-items: center;
                }
            }
        }

        .navigation-right-box{
            padding: 0;
            list-style-type: none;
            display: flex;
            align-items: center;

            .userImage{
                height: 34px;
                display: block;
                border-radius: 50%;
                margin: auto 10px;
            }

            .userEmail{
                display: flex;
                flex-direction: column;
                align-items: center;
                margin: auto 10px;
                width: 100%;

                h4{
                    margin: 0;
                    font-weight: 400;
                    color: $white;
                }
                small {
                    display: inline-block;
                    color: $olive;
                    font-weight: 400;
                }
            }

            .logout-button-container{
                padding: 0 10px;

                .logout-button{
                    text-align: center;
                    width: 100%;
                    padding: 8px 18px;
                    font-size: 1.15rem;
                    font-weight: 500;
                    color: $light-gray;
                    border-radius: 5px;
                    border:none;
                    cursor: pointer;
                    background-image: url(../../../images/bgx-o.png);

                    &:hover{ background-image: url(../../../images/bgx-2.jpg);}
                
                }
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .dashboard-header{
        .dashboard-navigation{
            .navigation-left-box{
                width: 100%;
                justify-content: space-between;
            }

            .navigation-right-box{
                display: none;
            }
        }
    }
}
