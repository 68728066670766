.dashboardCards{
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 2rem;
    margin-top: 0; 
    margin-bottom: 1.5rem;

    .d-card {
        display: flex;
        justify-content: space-between;
        background-color: $white;
        padding: 2rem;
        border-radius: 5px;

        .info{
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 5px 0;
           
            .number{
                font-size: 1.8rem;
                font-weight: 700;
                color: $dark-gray;
            }
            .title{
                color: $gray;
            }
        }
        .icon{
            font-size: 4em;
            color: $dark-gray;
            display: flex;
            justify-content: center;
            align-items: center;
        }

    }
}

@media only screen and (max-width: 1200px) {
    .dashboardCards{
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (max-width: 730px) {
    .dashboardCards{
        grid-template-columns: 100%;
    }
}