@import '../../../../styles/variables';

.addPackinglist-form{
    margin: 8px auto 20px;

    .addPackinglist-box{
        margin: 0 auto;
        padding: 30px 20px 10px;
        width: 470px;
        gap: 10px;
        background-color: $dark-gray;
        color: $white;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 5px;

        a{
            font-size: 1rem;
            font-weight: 400;
            color: $white;
            text-decoration: none;
            cursor: pointer;
            display: block;
            padding: 3px 5px;
            margin-bottom: 20px;
            border: 1px solid $white;
            border-radius: 5px;

            &:hover{
                color: $orange;
                border: 1px solid $orange;
            }
        }

        input, select{
            padding: 5px;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            border-radius: 5px;
            background-color: $white;
            color: $dark-gray;
            font-size: 1.1rem;
            font-weight: 500;
            border: none;
            outline: none;

            &::placeholder{
                font-size: 1rem;
                color: $gray;
            }
        }

        button {
            background-image: url(../../../../images/bgx-o.png);
            color: $white;
            height: 100%;
            margin: 20px 0;
            padding: 5px 30px;
            border: none;
            border-radius: 5px;
            cursor: pointer;

            &:hover {
                background-image: url(../../../../images/bgx-2.jpg);
                border: none;
            }
        }
    }
}

@media screen and (max-width: 540px){
    .addPackinglist-form{
        .addPackinglist-box{
            width: 100%;

            input,select{
                width: 100%;
                font-size: 1.1rem;
                font-weight: 500;
            }
        }
    }
}

