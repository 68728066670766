.listPaginationRow{
    padding: 0 10px;
    border-radius: 5px;

    .paginationBttns{
        margin-top: 50px;
        width: 100%;
        height: 40px;
        list-style:none;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 20px 10px;

        a{
            padding: 3px 10px;
            background-color: lighten($gray,  1%);
            font-size: 1rem;
            font-weight: 500;
            margin: 8px;
            border-radius: 5px;
            border: 1px solid $dark-gray;
            color: $white;
            cursor: pointer; 
            text-decoration: none;

            &:hover{
                color:$white;
                background-color: $orange;
            }
        }
    }

    .paginationActive{
        a{
            text-decoration: none;
            color:$white;
            background-color: $dark-gray;
        }
    }

}
.packinglist-info-container{
    margin: 10px 0;
    padding: 15px;
    gap: 20px;
    width: 100%;
    background-color: $white;
    border: 1px solid lighten($olive, 10%);
    border-radius: 5px;
    box-shadow: 0 0 15px $olive;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .packinglist-info{
        display: flex;
        flex-direction: column;
        width: 100%;

        .packinglist-info-title {
            font-size: 1.2rem;
            font-weight: 500;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 10px;

            span{
                padding: 5px 25px;
                background-image: url(../../../images/bgx-o.png);
                color: darken($dark-gray, 5%);
                text-transform: capitalize;
                border-radius: 3px;
            }
        }

        .packinglist-info-h5 {
            padding: 5px;
            font-size: .8rem;
            font-weight: 400;
            color: $dark-gray;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .packinglist-info-h5-span{
                em{
                    text-transform: capitalize;
                }
            }

            .packinglist-info-eye-button {
                margin: 0 20px;
                padding: 0 ;
                color: lighten($olive, 30%);
                cursor: pointer;
                font-size: 1.5rem;
                display: flex;
                justify-content: center;

                &:hover {
                    color: $orange;
                } 
            } 
        }
        
    }

    .packinglist-info-buttons-container{
        margin: 0;
        padding: 0 0 0 10px;
        width: 100px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        border-left: 1px solid $gray;

        .packinglist-info-update-button {
            margin: 0;
            padding: 0;
            color: lighten($olive, 30%);
            cursor: pointer;
            font-size: 1.5rem;

            &:hover {
                color: $orange;
            } 
            
        }

        .packinglist-info-buttons-cirkle{
            border: 1px solid darken($red, 10%);
            width: 40px;
            height: 40px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;

            .packinglist-info-delete-button{
                margin: 0;
                padding: 6px 0 0;
                background-color: $white;
                color:  darken($red, 10%);
                cursor: pointer;
                font-size: 1.6rem;
                border: none;

                &:hover{
                    color: lighten($red, 20%);
                }
            }
        }
    }
}

@media screen and (max-width: 800px){
    .packinglist-info-container{
        flex-direction: column;
        .packinglist-info{
            .packinglist-info-title{
                flex-direction: column;
                justify-content: center;
            }

            .packinglist-info-h5 {
                flex-direction: column;
                justify-content: space-between;

                &.mobile{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-around;
                }
            }
        }

        .packinglist-info-buttons-container{
            padding-top: 5px;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            border-left: none;
            border-top: 1px solid $dark-gray;
        }
    }  
}