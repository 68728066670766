.go-back-link{
    margin: 10px 0 20px;
    width: 100px;
    text-decoration: none;
    background-color: $dark-gray;
    color: $white;
    font-size: 1rem;
    font-weight: 500;
    padding: 8px 20px;
    border-radius: 3px;
    display: block;
    text-align: center;
    box-shadow: 0 0 5px $dark-gray;
    border: 1px solid $dark-gray;

    &:hover{
        background-color: $orange;
        border: 1px solid lighten($gray, 5%);
    }
}

.updatePackinglist-form{
    margin: 8px auto 20px;

    .updatePackinglist-box{
        margin: 0 auto;
        padding: 30px 20px 10px;
        max-width: 470px;
        gap: 10px;
        background-color: $dark-gray;
        color: $white;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 5px;

        .updatePackinglist-label-input{
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            border-radius: 5px;
            color: $white;
            font-size: 1.1rem;
            font-weight: 500;

            input{
                padding: 5px;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                border-radius: 5px;
                background-color: $light-gray;
                color: $dark-gray;
                font-size: 1.1rem;
                font-weight: 500;
                border: none;
                outline: none;
    
                &::placeholder{
                    font-size: 1rem;
                    color: $gray;
                }
            }
        }

        button{
            background-color: lighten($dark-gray, 15%);
            color: $white;
            height: 100%;
            margin: 20px 0;
            padding: 8px 30px;
            border: none;
            border-radius: 5px;
            cursor: pointer;

            &:hover {
                background-color: $orange;
            }
        }
    }
}