.login-container{
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;

    .login-form{
        background-color: $olive-rgba;
        padding: 25px;
        border: 1px solid $gray;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        box-shadow: 0 4px 16px 0 $dark-gray;

        img{
            margin: 0 auto;
        }

        p{
            margin: 0;
            padding: 8px 5px;
            width: 100%;
            border-radius: 3px;
            background-color: lighten($red, 35%);

        }

        form{
            display: flex;
            flex-direction: column;
            gap: 20px;
            width: 290px;

            input{
                padding: 8px 5px;
                outline: none;
                border-radius: 3px;
                border: none;
                font-size: 1rem;
                background-color: $light-gray;
                color: darken($dark-gray, 5%);
            }

            button{
                padding: 8px 5px;
                border-radius: 3px;
                border: none;
                font-size: 1rem;
                background-color: $dark-gray;
                color: $light-gray;
                cursor: pointer;

                &:hover{
                    background-color: lighten($dark-gray, 5%);
                }
            }
        }

        ul{
            margin: 0;
            padding: 0;
            list-style-type: none;
            display: flex;
            width: 100%;
            justify-content: space-between;
            
            li{
                a{
                    font-size: 1rem;
                    color: $light-gray;
                    cursor: pointer;

                    &:hover{
                        color: lighten($dark-gray, 5%);
                    }
                }
            }
        }
    }
}