.carParts-collection{
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .partCard{
        padding: 10px;
        border-radius: 5px;
        background-color: $white;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        text-decoration: none;
        cursor: pointer;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

        &:hover{
            background-color: $white;
        }

        .top{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            align-items: flex-start;
            .left{
                display: flex;
                flex-direction: column;
                gap: 10px;
                h1{
                    margin: 0;
                    color: $dark-gray;
                    font-size: 1.2rem;
                }
                span{
                    margin: 0;
                    font-size: 1rem;
                    padding: 7px 10px;
                    background-color: $dark-gray;
                    color: $light-gray;
                    border-radius: 5px;
                }
            }
            h6{
                margin: 0;
                color: darken($light-gray, 5%);
            }
        }
        .content{
            margin: 0;
            padding: 0;
            list-style-type: none;
            display: flex;
            gap: 10px;
            text-decoration: 'none';
        }
    }
}