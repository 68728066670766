@import '../../../styles/variables';

.dashboard-content-container{
    h3{
        margin: 0;
        padding-top: 5px;
        padding-bottom: 16px;
        width: 100%;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.1;
        display: flex;
        justify-content: flex-end;
        color: darken($light-gray, 15%);
      
        span{
            padding: 0 3px;
            text-decoration: underline;
            color: $dark-gray;
        }
    }
}
