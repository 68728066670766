$font-nunito: 'Nunito', sans-serif;

$olive-rgba: linear-gradient(90deg, rgba(100,94,70,1) 7%, rgba(155,143,100,1) 51%, rgba(100,94,70,1) 95%);
$olive: rgba(100,94,70,1);

$white: #fff;

$red: #ff0000;

$gray:rgb(100, 97, 97);
$light-gray: rgb(225, 224, 224);
$dark-gray: #414141;

$orange: #CB995A;

$black: #000000;

$green: green;

$sand: #92826f;