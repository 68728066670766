.publicHeader{
    margin: 0;
    background-color: transparent;
    position: fixed;
    padding: 15px;
    width: 100%;
    z-index:100;

    nav{
        margin: 0 auto;
        max-width: 1200px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 30px;

        .logo{
            display: block;
            cursor: pointer;
        }

        .navigation{
            display: flex;
            // width: 100%;
            // justify-content: flex-end;
            gap: 30px;
    
            a{
                border: 2px solid $light-gray;
                padding: 8px 20px;
                border-radius: 4px;
                color: $light-gray;
                text-decoration: none;
                font-size: 1.1rem;
                font-weight: 600;
    
                &:hover{
                    color: darken($dark-gray, 5%);
                    border-bottom: 2px solid darken($dark-gray, 5%);
                }
            }
        }
    }
}



@media screen and (max-width: 550px){
    header{
        nav{
        flex-direction: column;
        }
    }
}