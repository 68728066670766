.car-card-container{
    margin-top: 15px;
    padding: 10px;
    background-color: $white;
    color: $dark-gray;
    list-style-type: none;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    li{
        height: 140px;
    }

    // id box
    .id-box{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-right: 1px solid $light-gray;  
        padding-right: 5px;
        width: 160px;

        h2{
            margin: 0;
            color: $dark-gray;
            font-size: 1rem;
            font-weight: 700;

            span{
                font-size: 0.9rem;
                color: lighten($dark-gray, 25%);
            }
        }

        span{
            font-size: 0.7rem;
            color: $light-gray;
            width: 160px;
        }
    }

    // img box
    .img-box{
        margin: 0;
        padding: 0;
        width: 195px;
        height: 140px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

        img{
            margin: 0;
            pad: 0;
            display: block;
            width: 195px;
            height: 100%;  
            object-fit: cover;
            object-position:center center;
        }
    }

    .images{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 4px;
        width: 50px;
        
        img{
            margin: 0;
            pad: 0;
            display: block;
            width: 45px;
            height: 30px;
            object-fit:cover;
            object-position: center center;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 4px;
            cursor: pointer;
        }
    }

    // Car info first box 
    .car-info-first-box{
        border-left: 1px solid $light-gray; 
        border-right: 1px solid $light-gray;   
        padding-left: 10px;
        width: 900px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;

        h1{
            margin: 0;
            width: 100%;
            font-size: 1.1rem;
            font-weight: bold;
            color: $dark-gray;
            padding-bottom: 20px;
        }
        .info-layout{
            display: flex;
            flex-wrap: wrap;
            gap: 5px;

            .line{
                width: 280px;
                font-size: 1rem;
                color: $dark-gray;
                line-height: 1.3rem;
            }
        }
    }

    // Car info secod box
    .car-info-second-box{
        border-right: 1px solid $light-gray;   
        padding-left: 10px;
        width: 190px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
    }

    // chekboxes
    // .chekboxes{
    //     border-right: 1px solid $light-gray;
    //     padding-right: 10px;
    //     width: 100px;
    //     display: flex;
    //     flex-direction: column;
    //     gap: 15px;
        
    //     p{
    //         font-size: 1rem;
    //         text-align: center;
    //     }
    //     form{
    //         .form-line{
    //             display: flex;
    //             justify-content: space-between;
    //             gap: 5px;
    //         }

    //     }


    // }

    //action
    .action{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 3px;

        .actionButtons{
            text-decoration: none;
            cursor: pointer;
            border: none;
            background-color: $dark-gray;
            color: $white;
            font-size: 1rem;
            width: 110px;
            padding: 3px;
            border-radius: 3px;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

            &:hover{
                background-color: $orange;
            }
        } 
    }
}

@media screen and (max-width: 1000px) {
    .car-card-container{
        flex-direction: column;
        li{
            height: 100%;
        }
        .id-box{
            width: 100%;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            border-right: none;
            padding-bottom: 5px;
            border-bottom: 1px solid $light-gray;    
        }
        .img-box{
            margin: 0 auto;
            width: 310px;
            height: 100%;

            img{
                width: 100%;
            }
        }
        .car-info-first-box{
            border-left: none;  
            border-right: none;   
            border-top: 1px solid $light-gray;
            padding-top: 10px;
            padding-left: 0;
            width: 100%;
        }

        // .chekboxes{
        //     padding-top: 10px;
        //     border-right: none;
        //     border-top: 1px solid $light-gray;
        //     width: 100%;

        // }

        .action{
            border-top: 1px solid $light-gray;
            padding-top: 10px;
            display: flex;
            flex-direction: row;
            gap: 10px;
        }
       
    
    } 
}

@media screen and (max-width: 650px) {
    .car-card-container{
        .img-box{
            width: 100%;
        }
        .images{
            flex-direction: row;
            justify-content: space-around;
            flex-wrap: wrap;
            
            
        }
    } 
}