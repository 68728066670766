@import '../../styles/variables';

.dashboard-frame-container{
   margin: 0;
   padding: 0;
   background-image: url(../../images/bgx-o.png);
   color: $white;
   width: 100%;
   min-height: 100vh;

   .main-content{
      min-height: 100vh;
      height: 100%;
      padding: 90px 15px 30px 105px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      transition: all 0.5s ease;

      &.active{
         padding: 90px 15px 30px 285px;
      }
   }
}

@media screen and (max-width: 800px){
   .dashboard-frame-container{
      .main-content {
         padding: 90px 15px 30px;
        

         &.active{
            padding: 90px 15px 30px;
         }
      }
   }
}