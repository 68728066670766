@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

@import 'variables';
@import 'utilits';

@import '../components/Frame/Header/header';
@import '../pages/public/Login/login';
@import '../pages/public/PageNotFound/pageNotFound';

@import '../components/DashboardFrame/dashboardFrame';
@import '../components/DashboardFrame/D_Header/d_header';
@import '../components/DashboardFrame/D_Sidebar/d_sidebar';
@import '../components/DashboardFrame/D_active_Sidebar/_d_active_sidebar';
@import '../components/DashboardFrame/D_Content/d_content';
@import '../components/DashboardFrame/D_Content/DashboardCards/dashboardCards';
@import '../components/DashboardFrame/D_Content/CardWithButton/cardWithButton';
@import '../components/DashboardFrame/D_Content/SingleCard/singleCard';
@import '../components/DashboardFrame/D_Footer/d_footer';

@import '../pages/authorized/Cars/CarContainer/carContainer';
@import '../pages/authorized/Cars/NewCarAddForm/newCarAddForm';
@import '../pages/authorized/Cars/AddNewCar/addNewCar';

@import '../pages/authorized/Cars/CarCard/carCard';
@import '../pages/authorized/Cars/CarPage/carPage';
@import '../pages/authorized/Cars/CarParts/carParts';
@import '../pages/authorized/Cars/CarPartsCollection/carPartsCollection';
@import '../pages/authorized/Cars/UpdateCar/update';


@import '../pages/authorized/Packinglists/packinglists';
@import '../pages/authorized/Packinglists/AddNewPackinglist/addPackinglist';
@import '../pages/authorized/Packinglists/UpdatePackinglist/updatePackinglist';
@import '../pages/authorized/Packinglists/AddParts/addParts';
@import '../pages/authorized/Packinglists/PrintSavedParts/printSavedParts';
@import '../pages/authorized/Packinglists/PartsList/partsList';
@import '../pages/authorized/Settings/settings';
