.settings-companys{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 30px;
    

    form {
        width: 270px;
        display: flex;
        flex-direction: column;
        gap: 10px;

        input{
            padding: 8px 10px;
            border-radius: 5px;
            border: 1px solid $dark-gray;
            color: $dark-gray;
        }

        button{
            border: none;
            background-color: $dark-gray;
            cursor: pointer;
            padding: 8px 10px;
            border-radius: 5px;
            color: $light-gray;

            &:hover{
                background-color: $orange;
            }
        }
    }

    .companys-list{
        display: flex;
        flex-direction: column;
       

        .box{
            border: 1px solid #414141; 
            padding: 10px;
            margin-top: 10px;
            border-radius: 5px;
            text-align: center;
            p{
                margin: 0;
                display: flex;
                justify-content: space-between;
                gap: 10px;
                color: $gray;
                strong{
                    color: $dark-gray;
                }
            }

            button{
                margin-top: 20px;
                border: none;
                background-color: darken($red, 20%);
                cursor: pointer;
                padding: 5px 10px;
                border-radius: 5px;
                color: $light-gray;

                &:hover{
                    background-color: darken($red, 5%);
                }
            }
        }

    }

}

.settings-carBrands{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    form{
        display: flex;
        flex-direction: column;
        gap: 20px;

        input{
            padding: 8px 10px;
            border-radius: 5px;
            border: 1px solid $dark-gray;
            color: $dark-gray;
        }

        button{
            border: none;
            background-color: $dark-gray;
            cursor: pointer;
            padding: 8px 10px;
            border-radius: 5px;
            color: $light-gray;

            &:hover{
                background-color: $orange;
            }
        }
    }
    p{
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 650px){
    
}