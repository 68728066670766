.print-pl-container{
    margin: 0 auto;
    padding: 15px;
    max-width: 1100px;
    height: 100%;
    min-height: 100vh;
    background-color: $white;
    color: $black;

    .print-pl-container-div{
        padding: 10px 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .print-pl-icon{
            color: $light-gray;
            width: 20px;
            height: 20px;
            padding: 5px;
            font-size: .7rem;
            background-color: $gray;
            border-radius: 50%;
            cursor: pointer;
            text-decoration: none;
            &:hover{
                background-color: $orange;
            }
        }
    }

    .print-pl-info-box{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        h2{
            color: $black;
            font-size: 1.7rem;
            font-weight: 600;
            line-height: 1.75rem;
            text-transform: capitalize;
        }

        h4{
            margin-top: 20px;
            color: $dark-gray;
            font-size: 1rem;
            font-weight: 500;
            line-height: 1.1rem;
            span{
                font-weight: 600;
                color: $black;
            }
        }

        div{
            margin-top: 20px;
            width: 100%;
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
            align-items: center;
            ul{
                list-style-type: none;
                margin: 0;
                padding: 0;
                li{
                    margin-top: 10px;
                }
            }
        }
    }

    .print-pl-table{
        display: block;
        color: $black;
        margin-top: 30px;
        margin-bottom: 20px;
        border: 1px solid $light-gray;
        width: 100%;

        thead{
            tr{
                th{
                    background-color: $light-gray;
                    color: $dark-gray;
                    font-size: .7rem;
                    font-weight: 400;
                    padding: 3px 5px;
                    text-align: center;
                    border: 1px solid $dark-gray;
                    border-collapse: collapse;

                }

                .table-partName{
                    width: 100%;
                }
            }
        }

        tbody{
            tr{
                td{
                    background-color: $white;
                    color: $black;
                    font-size: .8rem;
                    font-weight: 400;
                    padding: 5px;
                    text-align: center;
                    border: 1px solid $dark-gray;
                    border-collapse: collapse;
                }
                .print-pl-table-col1{
                    width: 40px;
                }
                .print-pl-table-col2{
                    width: 100%;
                }
                .print-pl-table-col{
                    width: 130px;
                }
            }
        }
    }

    .warningText{
        display: none;
    }
}

@media screen and (max-width: 1000px) {
    .print-pl-container{
        .print-pl-table{
            display: none;
        }

        .warningText{
            margin: 20px auto;
            display: block;
            max-width: 300px;
            padding: 20px;
            color: $red;
            border: 1px solid $dark-gray;
            border-radius: 5px;
        }
    }
}